import { PrismicImg } from './prismic';

export enum PrismicPartnerUids {
  BASS_UTOPIA = 'bass_utopia',
}

export interface PrismicPartner {
  uid: string;
  prismicId: string;
  data: {
    name: string;
    logo: PrismicImg;
  };
}
