// extracted by mini-css-extract-plugin
export var page = "index-module--page--jj-jJ";
export var hero = "index-module--hero--1dyNa";
export var heroVideo = "index-module--heroVideo--3a-9p";
export var heroImg = "index-module--heroImg--auGlh";
export var heading = "index-module--heading--3cFc-";
export var signup = "index-module--signup--155mP";
export var signupBlurb = "index-module--signupBlurb--1rFd9";
export var signupForm = "index-module--signupForm--99qBn";
export var articleHeading = "index-module--articleHeading--3h16t";
export var articleHeadingIntro = "index-module--articleHeadingIntro--3dDbY";
export var featuredArticles = "index-module--featuredArticles--24Yf7";
export var mainFeatured = "index-module--mainFeatured--1JjaN";
export var otherFeatured = "index-module--otherFeatured--1Q3CL";
export var featuredArticle = "index-module--featuredArticle--2yCSe";
export var featuredArticleText = "index-module--featuredArticleText--nrCwb";
export var featuredArticleTitle = "index-module--featuredArticleTitle--3SwLZ";
export var featuredArticleSummary = "index-module--featuredArticleSummary--7iLPE";
export var featuredArticleBadge = "index-module--featuredArticleBadge--3q5r3";
export var featuredPublicationDate = "index-module--featuredPublicationDate--13GVG";
export var articlesGiveaways = "index-module--articlesGiveaways--1mIRV";
export var articlesColumn = "index-module--articlesColumn--bMt9p";
export var giveawaysColumn = "index-module--giveawaysColumn--2g3aP";
export var giveawaysHeading = "index-module--giveawaysHeading--3-5sa";
export var articles = "index-module--articles--N-72H";
export var article = "index-module--article--Zn7zz";
export var allArticles = "index-module--allArticles--dop7F";
export var videoIcon = "index-module--videoIcon--21JSe";
export var giveaway = "index-module--giveaway--30LWw";
export var giveawayImg = "index-module--giveawayImg--3aFD6";
export var giveawayText = "index-module--giveawayText--2-mCi";
export var giveawayHighlight = "index-module--giveawayHighlight--5WjHj";
export var giveawayTitle = "index-module--giveawayTitle--Cjffu";
export var contributorsHeading = "index-module--contributorsHeading--_CdkW";
export var contributors = "index-module--contributors--3EWpN";
export var contributor = "index-module--contributor--3izxF";
export var contributorImg = "index-module--contributorImg--1dVjr";
export var contributorName = "index-module--contributorName--1xIhx";
export var partnersSection = "index-module--partnersSection--1h17h";
export var partners = "index-module--partners--2S__x";
export var partner = "index-module--partner--z3uGp";
export var partnerImg = "index-module--partnerImg--19ELq";