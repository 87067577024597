import { RoutePaths } from './routes';

export const linkResolver = (doc: any) => {
  // URL for a category type
  if (doc.type === 'article') {
    return `${RoutePaths.ARTICLES}${doc.uid}/`;
  }
  // Backup for all other types
  return '/';
};
