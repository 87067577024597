import classNames from 'classnames';
import { graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import { ArticleCard } from '../../components/article_card/article_card';
import { ContentWrapper } from '../../components/content_wrapper/content_wrapper';
import { LinkButton } from '../../components/link_button/link_button';
import { Edge, GatsbyPageProps } from '../../gatsby-types';
import VideoIcon from '../../images/icon-video.inline.svg';
import Layout from '../../layouts/layout';
import { articleCardDate } from '../../lib/dates';
import { getImgixPath } from '../../lib/imgix';
import { linkResolver } from '../../lib/link_resolver';
import { PrismicArticleTags } from '../../lib/prismic_tags';
import { RoutePaths } from '../../lib/routes';
import { PrismicArticle } from '../../models/prismic_article';
import { PrismicContent } from '../../models/prismic_content';
import { PrismicGiveaway } from '../../models/prismic_giveaway';
import { PrismicPartner, PrismicPartnerUids } from '../../models/prismic_partner';
import { PrismicSeries } from '../../pages/series';
import * as styles from './index.module.less';

export const query = graphql`
  query($seriesTags: [String!], $topSeriesTags: [String!]) {
    non_series_articles: allPrismicArticle(
      limit: 12
      filter: { tags: { nin: $seriesTags } }
      sort: { fields: data___publication_date, order: DESC }
    ) {
      edges {
        node {
          ...PrismicArticleFragment
        }
      }
    }

    top_series_articles: allPrismicArticle(
      filter: { tags: { in: $topSeriesTags } }
      sort: { fields: data___publication_date, order: DESC }
    ) {
      nodes {
        ...PrismicArticleFragment
      }
    }

    allPrismicSeries(sort: { fields: data___display_order }) {
      nodes {
        ...PrismicSeriesFragment
      }
    }

    allPrismicGiveaway(
      filter: { data: { active: { eq: "active" } } }
      sort: { fields: first_publication_date }
    ) {
      edges {
        node {
          uid
          data {
            title {
              raw
            }
            thumbnail {
              alt
              dimensions {
                height
                width
              }
              url
            }
            link {
              url
            }
          }
        }
      }
    }

    allPrismicPartner(sort: { fields: data___name, order: ASC }) {
      edges {
        node {
          uid
          data {
            logo {
              alt
              dimensions {
                height
                width
              }
              url
            }
            name
          }
        }
      }
    }

    homeBanner: prismicContent(uid: { eq: "home-banner" }) {
      uid
      data {
        body {
          ... on PrismicContentDataBodyText {
            id
            primary {
              text {
                raw
              }
            }
            slice_label
            slice_type
          }
        }
      }
    }
  }
`;

const IndexPage = (props: GatsbyPageProps) => {
  const nonSeriesArticles = props.data.non_series_articles.edges.map(
    (edge: Edge) => edge.node
  ) as PrismicArticle[];
  const firstArticle = nonSeriesArticles[0];
  const secondArticle = nonSeriesArticles[1];
  const thirdArticle = nonSeriesArticles[2];
  const restArticles = nonSeriesArticles.slice(3, 12);

  const allSeries = props.data.allPrismicSeries.nodes as PrismicSeries[];
  const series1 = allSeries[0];
  const series2 = allSeries[1];
  const series3 = allSeries[2];
  const series1Articles =
    series1 != null
      ? (props.data.top_series_articles.nodes
          .filter((a: PrismicArticle) => a.tags.includes(series1.data.tag_name))
          .slice(0, 3) as PrismicArticle[])
      : [];
  const series2Articles =
    series2 != null
      ? (props.data.top_series_articles.nodes
          .filter((a: PrismicArticle) => a.tags.includes(series2.data.tag_name))
          .slice(0, 3) as PrismicArticle[])
      : [];
  const series3Articles =
    series3 != null
      ? (props.data.top_series_articles.nodes
          .filter((a: PrismicArticle) => a.tags.includes(series3.data.tag_name))
          .slice(0, 3) as PrismicArticle[])
      : [];

  const giveaways = props.data.allPrismicGiveaway.edges.map(
    (e: Edge) => e.node
  ) as PrismicGiveaway[];

  const partners = (props.data.allPrismicPartner.edges.map(
    (e: Edge) => e.node
  ) as PrismicPartner[]).filter((partner) => partner.uid !== PrismicPartnerUids.BASS_UTOPIA);

  const homeBanner = props.data.homeBanner as PrismicContent;

  return (
    <Layout>
      <section className={styles.page}>
        <ContentWrapper>
          <section className={styles.featuredArticles}>
            <Link
              to={`${RoutePaths.ARTICLES}${firstArticle.uid}/`}
              className={classNames(styles.featuredArticle, styles.mainFeatured)}
            >
              <div>
                <img
                  src={getImgixPath(firstArticle.data.thumbnail.url, {
                    w: 1000,
                  })}
                  alt={firstArticle.data.thumbnail.alt}
                  {...{ loading: 'lazy' }}
                />
              </div>
              <div className={styles.featuredArticleText}>
                <h3 className={styles.featuredArticleTitle}>
                  {RichText.asText(firstArticle.data.title.raw)}
                </h3>
                <p className={styles.featuredPublicationDate}>
                  {articleCardDate(firstArticle.data.publication_date)}
                </p>
                {firstArticle.data.article_summary.raw && (
                  <p className={styles.featuredArticleSummary}>
                    {RichText.asText(firstArticle.data.article_summary.raw)}
                  </p>
                )}
              </div>
              <div className={styles.featuredArticleBadge}>
                {firstArticle.tags.indexOf(PrismicArticleTags.VIDEO) > -1 ? (
                  <>
                    <span className={styles.videoIcon}>
                      <VideoIcon />
                    </span>{' '}
                    New Video
                  </>
                ) : (
                  'New Article'
                )}
              </div>
            </Link>

            <div className={styles.otherFeatured}>
              <Link
                to={`${RoutePaths.ARTICLES}${secondArticle.uid}/`}
                className={styles.featuredArticle}
              >
                <div>
                  <img
                    src={getImgixPath(secondArticle.data.thumbnail.url, {
                      w: 1000,
                    })}
                    alt={secondArticle.data.thumbnail.alt}
                    {...{ loading: 'lazy' }}
                  />
                </div>
                <div className={styles.featuredArticleText}>
                  <h3 className={styles.featuredArticleTitle}>
                    {RichText.asText(secondArticle.data.title.raw)}
                  </h3>
                  <p className={styles.featuredPublicationDate}>
                    {articleCardDate(secondArticle.data.publication_date)}
                  </p>
                  {secondArticle.data.article_summary.raw && (
                    <p className={styles.featuredArticleSummary}>
                      {RichText.asText(secondArticle.data.article_summary.raw)}
                    </p>
                  )}
                </div>
                <div className={styles.featuredArticleBadge}>
                  {secondArticle.tags.indexOf(PrismicArticleTags.VIDEO) > -1 ? (
                    <>
                      <span className={styles.videoIcon}>
                        <VideoIcon />
                      </span>{' '}
                      New Video
                    </>
                  ) : (
                    'New Article'
                  )}
                </div>
              </Link>

              <Link
                to={`${RoutePaths.ARTICLES}${thirdArticle.uid}/`}
                className={styles.featuredArticle}
              >
                <div>
                  <img
                    src={getImgixPath(thirdArticle.data.thumbnail.url, {
                      w: 1000,
                    })}
                    alt={thirdArticle.data.thumbnail.alt}
                    loading="lazy"
                  />
                </div>
                <div className={styles.featuredArticleText}>
                  <h3 className={styles.featuredArticleTitle}>
                    {RichText.asText(thirdArticle.data.title.raw)}
                  </h3>
                  <p className={styles.featuredPublicationDate}>
                    {articleCardDate(thirdArticle.data.publication_date)}
                  </p>
                  {thirdArticle.data.article_summary.raw && (
                    <p className={styles.featuredArticleSummary}>
                      {RichText.asText(thirdArticle.data.article_summary.raw)}
                    </p>
                  )}
                </div>
                <div className={styles.featuredArticleBadge}>
                  {thirdArticle.tags.indexOf(PrismicArticleTags.VIDEO) > -1 ? (
                    <>
                      <span className={styles.videoIcon}>
                        <VideoIcon />
                      </span>{' '}
                      New Video
                    </>
                  ) : (
                    'New Article'
                  )}
                </div>
              </Link>
            </div>
          </section>

          <section className={styles.articlesGiveaways}>
            <div className={styles.articlesColumn}>
              {series1Articles.length > 0 && (
                <>
                  <h2 className={styles.articleHeading}>
                    <span className={styles.articleHeadingIntro}>Series</span>
                    <Link to={`${RoutePaths.SERIES}${series1.uid}`}>
                      {RichText.asText(series1.data.name.raw)}
                    </Link>
                  </h2>
                  <ol className={styles.articles}>
                    {series1Articles.map((article) => (
                      <li className={styles.article} key={article.uid}>
                        <ArticleCard article={article} />
                      </li>
                    ))}
                  </ol>
                </>
              )}

              {series2Articles.length > 0 && (
                <>
                  <h2 className={styles.articleHeading}>
                    <span className={styles.articleHeadingIntro}>Series</span>
                    <Link to={`${RoutePaths.SERIES}${series2.uid}`}>
                      {RichText.asText(series2.data.name.raw)}
                    </Link>
                  </h2>
                  <ol className={styles.articles}>
                    {series2Articles.map((article) => (
                      <li className={styles.article} key={article.uid}>
                        <ArticleCard article={article} />
                      </li>
                    ))}
                  </ol>
                </>
              )}

              {series3Articles.length > 0 && (
                <>
                  <h2 className={styles.articleHeading}>
                    <span className={styles.articleHeadingIntro}>Series</span>
                    <Link to={`${RoutePaths.SERIES}${series3.uid}`}>
                      {RichText.asText(series3.data.name.raw)}
                    </Link>
                  </h2>
                  <ol className={styles.articles}>
                    {series3Articles.map((article) => (
                      <li className={styles.article} key={article.uid}>
                        <ArticleCard article={article} />
                      </li>
                    ))}
                  </ol>
                </>
              )}

              <h2 className={styles.articleHeading}>More</h2>
              <ol className={styles.articles}>
                {restArticles.map((article) => (
                  <li className={styles.article} key={article.uid}>
                    <ArticleCard article={article} />
                  </li>
                ))}
              </ol>

              <div>
                <LinkButton to={RoutePaths.ARTICLES_LIST}>See All Articles ›</LinkButton>
              </div>
            </div>

            <div className={styles.giveawaysColumn}>
              {giveaways.length > 0 && (
                <div>
                  <h2 className={styles.giveawaysHeading}>Giveaways</h2>
                  {giveaways.map((giveaway) => (
                    <Link
                      to={giveaway.data.link.url}
                      key={giveaway.uid}
                      className={styles.giveaway}
                    >
                      <div className={styles.giveawayImg}>
                        <img
                          src={getImgixPath(giveaway.data.thumbnail.url, {
                            w: 500,
                          })}
                          alt={giveaway.data.thumbnail.alt}
                          loading="lazy"
                        />
                      </div>
                      <div className={styles.giveawayText}>
                        <p className={styles.giveawayHighlight}>GIVEAWAY</p>
                        <p className={styles.giveawayTitle}>
                          {RichText.asText(giveaway.data.title.raw)}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              )}

              <div className={styles.partnersSection}>
                <h2 className={styles.contributorsHeading}>Partners</h2>
                <div className={styles.partners}>
                  {partners.map((partner) => (
                    <Link
                      to={`${RoutePaths.PARTNERS}${partner.uid}`}
                      key={partner.uid}
                      className={styles.partner}
                    >
                      {partner.data.logo && (
                        <span className={styles.partnerImg}>
                          <img src={getImgixPath(partner.data.logo.url, { w: 100 })} />
                        </span>
                      )}
                      <span>{partner.data.name}</span>
                    </Link>
                  ))}
                </div>
                <LinkButton to={RoutePaths.PARTNERS}>See All Partners ›</LinkButton>
              </div>

              {homeBanner && (
                <div>
                  {homeBanner.data.body.map((slice, i: number) => {
                    switch (slice.slice_type) {
                      case 'text': {
                        return (
                          <React.Fragment key={i}>
                            <RichText render={slice.primary.text.raw} linkResolver={linkResolver} />
                          </React.Fragment>
                        );
                      }
                    }
                  })}
                </div>
              )}
            </div>
          </section>
        </ContentWrapper>
      </section>
    </Layout>
  );
};

export default IndexPage;
